import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transitionbluetoblack',
  templateUrl: './transitionbluetoblack.component.html',
  styleUrls: ['./transitionbluetoblack.component.css']
})
export class TransitionbluetoblackComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
