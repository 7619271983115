import { Component, OnInit } from '@angular/core';

import * as animations  from '../animations'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: animations.AllAnimations
  /*[   
    animations.HeightOpacityInAnimationEnter,
    animations.WidthOpacityInAnimationEnter,
    animations.OpacityInAnimationEnter

    // https://webdesignerwall.com/tutorials/easy-css-animation-transition-transforms
    // https://ironion.com/blog/2015/07/23/css-using-transform-translate-for-animations-and-position/
    // https://blog.laas.sh/bonus-step-3-5-routing-animations/
    /*trigger('heightOpacityInAnimation', [
      transition(':enter', [     
        style({ height: 0, opacity: 0}),
          animate('1000ms', style({
            height: "*",
            opacity: 1
          }))
        
      ])
    ]),
    trigger('widthOpacityInAnimation', [
      transition(':enter', [     
        style({ width: 0, opacity: 0}),
          animate('1200ms', style({
            width: "*",
            opacity: 1
          }))
        
      ])
    ])
  ]*/})
  

export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
