import { ChangeDetectorRef, Inject,ElementRef,  Component, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

import { Router, Event as NavigationEvent,  NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';


import * as animations  from './animations'
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query
} from '@angular/animations';
import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { StateService } from './state-service';

// http://animationsftw.in/#/routing/page4
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: animations.AllAnimations
  /*[
    animations.HeightOpacityInAnimationEnter,
    animations.WidthOpacityInAnimationEnter,
    animations.OpacityTranslateXFromLeftInAnimationEnter,
    animations.OpacityTranslateXFromRightInAnimationEnter
  ,
    trigger('routeAnimations', [
    
      transition('homePage => HomePage', [
        query(':enter', style({ opacity: 0 })),
        query(':leave', [
          animate('1s', style({ opacity: 0 }))
        ]),
        query(':enter', [
          animate('1s', style({ opacity: 1 }))
        ]),
      ])
    ])*/
    /*
    trigger('pageAnimations', [
      transition(':enter', [
        // animation for the page entering
        animate('1s', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        // animation for the page leaving
        animate('1s', style({ opacity: 0 }))
      ])
    ])

    trigger('animationTrigger', [
      transition(':enter', [       
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
        
      ]),
      transition(':enter', [
        animate('500ms', style({ opacity: 0 }))
      ])
    ]),
]*/
  
})
export class AppComponent implements OnInit  {

  
  showmobilemenu: boolean = false;
  title = 'Clayster';

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef, private media: MediaMatcher, public state: StateService) {
    router.events.forEach((event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        this.showmobilemenu = false;
      }
    });




  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }

        window.scrollTo(0, 0);
        window.setTimeout(function()
        {
          console.log("elapsed");
          this.scrolldisabled = false;
        }, 1000);

        /*var scrollToTop = window.setInterval(function () {
            var pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
                this.scrolldisabled = false;
            }
        }, 16); // how fast to scroll (this equals roughly 60 fps)*/
    });
}

  hidemenu()
  {
    this.showmobilemenu=false;
  }

  showmenu()
  {
    this.showmobilemenu= true;
  }
  /*
  const routes: Routes = [ 
    {component: HomeComponent, path: '', data: {animation: 'Home' }},
    {component: AboutComponent, path: 'about-us', data: {animation: 'About' }},
    {component: ContactComponent, path: 'contact', data: {animation: 'Contact' }},
    {component: IntroductionComponent, path: 'introduction', data: {animation: 'Introduction' }},
    {component: ProductsComponent, path: 'products', data: {animation: 'Product' }},
  ];*/

  Routes = [
    '/',
    '/about-us',
    '/introduction',
    '/products',
    '/contact'
  ];
  activepage = 0;

  //lastscroll = new Date();
  scrolldisabled = false;

  //https://github.com/orizens/ngx-infinite-scroll

  onScroll() {
    /*
    var now : Date = new Date();
    var diff = now.getTime() - this.lastscroll.getTime();

    console.log("diff: " + diff.toString());

    if(diff < 1000)
      return;

      this.lastscroll = new Date();*/
    /*if(this.scrolldisabled)
      return;*/
    this.scrolldisabled = true;

    this.activepage++;
    this.activepage %= this.Routes.length;
    console.log("active page: " + this.activepage)
    this.router.navigateByUrl( this.Routes[this.activepage] );
  }
}
