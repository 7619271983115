import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';

import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {StateService} from './state-service';


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ProductsComponent } from './products/products.component';
import { TechnologyComponent } from './technology/technology.component';
import { ContactComponent } from './contact/contact.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { TransitionbluetowhiteComponent } from './transitionbluetowhite/transitionbluetowhite.component';
import { TransitionwhitetoblueComponent } from './transitionwhitetoblue/transitionwhitetoblue.component';
import { OtherfeaturesComponent } from './otherfeatures/otherfeatures.component';
import { NewsComponent } from './news/news.component';
import { TransitionbluetoblackComponent } from './transitionbluetoblack/transitionbluetoblack.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ProductsComponent,
    TechnologyComponent,
    ContactComponent,
    IntroductionComponent,
    TransitionbluetowhiteComponent,
    TransitionwhitetoblueComponent,
    OtherfeaturesComponent,
    NewsComponent,
    TransitionbluetoblackComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    InfiniteScrollModule
  ],
  providers: [StateService, MediaMatcher,Platform],
  bootstrap: [AppComponent]
})
export class AppModule { }
