import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { TechnologyComponent } from './technology/technology.component';
import { ProductsComponent } from './products/products.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { MediaMatcher, LayoutModule } from '@angular/cdk/layout';

const routes: Routes = [ 
  {component: HomeComponent, path: '', data: {animation: 'Home' }},
  {component: AboutComponent, path: 'about-us', data: {animation: 'About' }},
  {component: ContactComponent, path: 'contact', data: {animation: 'Contact' }},
  {component: IntroductionComponent, path: 'introduction', data: {animation: 'Introduction' }},
  {component: ProductsComponent, path: 'products', data: {animation: 'Product' }},
];

@NgModule({
  imports: [RouterModule.forRoot(routes), LayoutModule],
  exports: [RouterModule,  LayoutModule]
})
export class AppRoutingModule { }
