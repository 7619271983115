import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-otherfeatures',
  templateUrl: './otherfeatures.component.html',
  styleUrls: ['./otherfeatures.component.css']
})
export class OtherfeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
