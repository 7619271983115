import {
    trigger,
    state,
    style,
    animate,
    transition,
    query
  } from '@angular/animations';

export var HeightOpacityInAnimationEnter=
trigger('enterHeightOpacityInAnimation', [
    transition(':enter', [     
      style({ height: 0, opacity: 0}),
        animate('1000ms', style({
          height: "*",
          opacity: 1
        }))
      
    ])
  ]);

export var WidthOpacityInAnimationEnter =
  trigger('enterWidthOpacityInAnimation', [
    transition(':enter', [     
      style({ width: 0, opacity: 0}),
        animate('1200ms', style({
          width: "*",
          opacity: 1
        }))
      
    ])
  ]);

  export var OpacityInAnimationEnter =
  trigger('enterOpacityInAnimation', [
    transition(':enter', [     
      style({ opacity: 0}),
        animate('1200ms', style({
          opacity: 1
        }))
      
    ])
  ]);

  export var OpacityTranslateXFromLeftInAnimationEnter =
  trigger('enterOpacityTranslateXFromLeftInAnimation', [
    transition(':enter', [     
      style({ opacity: 0, transform: "translateX(-200px)"}),
        animate('1200ms', style({
          opacity: 1,
          transform: "translateX(0px)",
        }))
      
    ])
  ]);
  export var OpacityTranslateXFromRightInAnimationEnter =
  trigger('enterOpacityTranslateXFromRightInAnimation', [
    transition(':enter', [     
      style({ opacity: 0, transform: "translateX(200px)"}),
        animate('1200ms', style({
          opacity: 1,
          transform: "translateX(0px)",
        }))
      
    ])
  ]);



export var HeightOpacityInAnimationVisible=
trigger('enterHeightOpacityInAnimation', [
    transition(':enter', [     
      style({ height: 0, opacity: 0}),
        animate('1000ms', style({
          height: "*",
          opacity: 1
        }))
      
    ])
  ]);

export var WidthOpacityInAnimationVisible =
  trigger('enterWidthOpacityInAnimation', [
    transition(':visible', [     
      style({ width: 0, opacity: 0}),
        animate('1200ms', style({
          width: "*",
          opacity: 1
        }))
      
    ])
  ]);

  export var OpacityInAnimationVisible =
  trigger('enterOpacityInAnimation', [
    transition(':visible', [     
      style({ opacity: 0}),
        animate('1200ms', style({
          opacity: 1
        }))
      
    ])
  ]);

  export var OpacityTranslateXFromLeftInAnimationVisible =
  trigger('enterOpacityTranslateXFromLeftInAnimation', [
    transition(':visible', [     
      style({ opacity: 0, transform: "translateX(-200px)"}),
        animate('1200ms', style({
          opacity: 1,
          transform: "translateX(0px)",
        }))
      
    ])
  ]);
  export var OpacityTranslateXFromRightInAnimationVisible =
  trigger('enterOpacityTranslateXFromRightInAnimation', [
    transition(':visible', [     
      style({ opacity: 0, transform: "translateX(200px)"}),
        animate('1200ms', style({
          opacity: 1,
          transform: "translateX(0px)",
        }))
      
    ])
  ]);

  export var AllAnimations = [
    OpacityInAnimationEnter,
    HeightOpacityInAnimationEnter,
    WidthOpacityInAnimationEnter,
    OpacityTranslateXFromLeftInAnimationEnter,
    OpacityTranslateXFromRightInAnimationEnter,

    OpacityInAnimationVisible,
    HeightOpacityInAnimationVisible,
    WidthOpacityInAnimationVisible,
    OpacityTranslateXFromLeftInAnimationVisible,
    OpacityTranslateXFromRightInAnimationVisible
  ]