import { ChangeDetectorRef, Inject,ElementRef,  Component, OnInit } from '@angular/core';
import { StateService } from '../state-service';
import { MediaMatcher } from '@angular/cdk/layout';


class NewsPost
{
  public title: string;
  public body: string;
  public when: string;
  public outlink: string = "";
  public additionalimg: string = "";
}


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit 
{
  mobileQuery: MediaQueryList;  
  tabletQuery: MediaQueryList;
  desktopQuery: MediaQueryList;
  private mediaQueryListener: () => void;
  NrEntries = 4;    

  public renderedPosts : NewsPost[] = [];

  public posts: NewsPost[] = 
  [
    { 
      "title": "Mobile 360 Security for 5G", 
      "when": "May 2019", 
      "body": "Clayster CEO Rikard Strid presented a speech 'Securing IOT Tech in Society",
      "outlink": "https://www.mobile360series.com/speaker/rikard-strid-7/",
      "additionalimg": null
    },
    { 
      "title": "Mobile World Congres Barcelona Demonstration", 
      "when": "February 2019", 
      "body": "Clayster show casing the use for hard identity with Mobile Connect in an Internet of Thing solution. The story of the demonstration involved renting a car and thereafter enabling insurance and extra services.",
      "outlink": "https://www.gsma.com/identity/winning-consumer-trust-digital-identity-at-the-mwcs-innovation-city",
      "additionalimg": "/assets/mwc2019demo.png"
    },    { 
      "title": "Mobile World Congres Barcelona Panel Discussion", 
      "when": "October 2018", 
      "body": "Clayster CEO Rikard Strid participated in panel discussion 'BPanel discussion: A hitchhiker’s guide to the growing pace of innovation within identity services in the mobile industry'.",
      "outlink": "https://www.gsma.com/identity/operators-are-primed-to-enable-the-transition-between-analogue-and-digital-worlds",
      "additionalimg": null
    },    { 
      "title": "From Vision to Reality: Delivering Commercial Success Through Identity Services", 
      "when": "October 2018", 
      "body": "Clayster CEO Rikard Strid participated in panel discussion 'Beyond authentication: monetising identity services'.",
      "outlink": "https://www.gsma.com/identity/events/mobile-connect-from-vision-to-reality",
      "additionalimg": null
    },    { 
      "title": "Mobile Connect Seminar: Reducing Fraud through Secure Authentication and ID Verification Services", 
      "when": "September 2018", 
      "body": "Clayster CEO Rikard Strid held a speech 'Future identity trends: IoT and ID management'.",
      "outlink": "https://www.gsma.com/identity/events/mobile-connect-seminar-reducing-fraud-through-secure-authentication-and-id-verification-services",
      "additionalimg": null
    },    { 
      "title": "Mobile World Congress San Francisco Demonstration", 
      "when": "June 2018", 
      "body": "Clayster participated in a GSMA demo for connected buildings. Demo includes 'reverse billing' and digital ownership. Provisioned devices in Clayster were consumed and real-time visualized in a SCADA system.",
      "outlink": null,
      "additionalimg": null
    },    { 
      "title": "Mobile World Congress Barcelona Demonstration", 
      "when": "May 2019", 
      "body": "February 2018. Clayster show casing the need for privacy and digital ownership with GSMA at Mobile World Congress, Barcelona. The moral of the demo, which included a fictional health care service where the patient wore a 'brain scanner', was to create awarenes and offering a solution private data exposed by connected things.",
      "outlink": "https://www.gsma.com/identity/eidas",
      "additionalimg": "/assets/mwc2018demo.png"
    },    { 
      "title": "Mobile Connect for Cross-Border Digital Services Lessons Learned from the eIDAS Pilot", 
      "when": "May 2019", 
      "body": "February 2018. Clayster participated in a GSMA project evaluating Mobile Connect and eIDAS. Our role was to implement and make use of eIDAS with Mobile Connect.",
      "outlink": "https://www.gsma.com/identity/wp-content/uploads/2018/02/MC-for-cross-border-digital-services_eIDAS_Feb2018-FINAL-web.pdf",
      "additionalimg": null
    },    { 
      "title": "Startup makes smart homes simple: \"Getting things connected should be natural\"", 
      "when": "February 2018", 
      "body": "Swedish newspaper 'Ny Teknik' published an article about Clayster.",
      "outlink": "https://www.nyteknik.se/digitalisering/startup-gor-smarta-hem-enkla-att-saker-kopplar-upp-sig-ska-vara-naturligt-6898032",
      "additionalimg": null
    },    { 
      "title": "Mobile Connect Summit Singapore", 
      "when": "November 2017", 
      "body": "Clayster CEO Rikard Strid presented a speech 'Powering the Internet of Things with Mobile Connect'.",
      "outlink": "https://www.gsma.com/identity/events/mobile-connect-summit-singapore",
      "additionalimg": null
    },    { 
      "title": "Mobile Connect Summit London", 
      "when": "April 2017", 
      "body": "Clayster CEO Rikard Strid participated in panel discussion 'Industry Experts Discuss: Powering the Internet of Things with Mobile Connect'.",
      "outlink": "https://www.gsma.com/identity/events/mobile-connect-summit-london-2",
      "additionalimg": null
    }
  ];

  updatePosts()
  {
    this.renderedPosts.splice(0, this.renderedPosts.length);
    for(var i = 0; i < this.NrEntries && i < this.posts.length; i++)
    {
      this.renderedPosts.push(this.posts[i]);
    }

  }

  loadmore()
  {
    this.NrEntries += 3;
    this.updatePosts();
  }

  constructor( private state : StateService, private changeDetectorRef: ChangeDetectorRef, private media: MediaMatcher,) { 

		this.mediaQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery = media.matchMedia('(max-width: 819px)');
		this.mobileQuery.addListener(this.mediaQueryListener);

    this.tabletQuery = media.matchMedia('(min-width: 819px) and (max-width: 1200px)');
		this.tabletQuery.addListener(this.mediaQueryListener);

    this.desktopQuery = media.matchMedia('(min-width: 1200px)');
		this.desktopQuery.addListener(this.mediaQueryListener);

    if(window.innerWidth < 915)
    {
      this.NrEntries = 3;
    }
    if(window.innerWidth>=819 && window.innerWidth < 1200)
    {
      this.NrEntries = 6;
    }
    if(window.innerWidth>=1200)
    {
      this.NrEntries = 9;
    }

    this.renderedPosts = this.posts.slice(0, this.NrEntries);

		this.mobileQuery.addListener(
      (e) => {      
            
        if(e.matches)
        {
          this.NrEntries = 3;
        }
    
      this.updatePosts();
      this.changeDetectorRef.detectChanges();
    });

		this.tabletQuery.addListener(
      (e) => {      
            
        if(e.matches)
        {
          this.NrEntries = 6;
        }
    
      this.updatePosts();
      this.changeDetectorRef.detectChanges();
    });
    
		this.desktopQuery.addListener(
      (e) => {      
            
        if(e.matches)
        {
          this.NrEntries = 9;
        }
    
      this.updatePosts();
      this.changeDetectorRef.detectChanges();
    });    

    this.updatePosts();

  }

  ngOnInit() {
  }

}
